const standardItems = [
  {
    title: 'Pokrycie dachowe',
    desc: 'Dach wykonany z blachy w kolorze antracytowym',
    img: 'roofing',
  },
  {
    title: 'Instalacja grzewcza',
    desc: 'Pompa ciepła + ogrzewanie podłogowe',
    img: 'heating_system',
  },
  {
    title: 'Instalacja elektryczna',
    desc: 'Instalacja z przygotowaniem pod fotowoltaikę',
    img: 'electrical_system',
  },
  {
    title: 'Fundamenty',
    desc: 'Fundamenty wykonane z żelbetonu',
    img: 'fundaments',
  },
  {
    title: 'Ściany wewnętrzne',
    desc: 'Ściany wewnętrzne wykonane z betonu komórkowego',
    img: 'interior_walls',
  },
  {
    title: 'Ogrodzenie',
    desc:
      'Panele 3D. Ogrodzenie w kolorze antracytowym + Dla każdego lokalu jest przynależny ogródek',
    img: 'fence',
  },
  {
    title: 'Pakiet trzyszybowy',
    desc: 'Okna z pakietem trzyszybowym w kolorze antracyt',
    img: 'triple_glazed',
  },
  {
    title: 'Plac rekreacyjny',
    desc: 'Duży plac zabaw + bezpośrednie zejście do Wkry',
    img: 'playground',
  },
  {
    title: 'Podjazd',
    desc:
      'Podjazd wykonany z kostki brukowej, zdalnie sterowana brama wjazdowa',
    img: 'driveway',
  },
];
export default standardItems;

import React, { FC, useState } from 'react';
import styled from 'styled-components';
import Headline from '@components/atoms/Headline/Headline';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Paragraph from '@components/atoms/Paragraph/Paragraph';
import { Icon } from '@iconify/react';
import arrowLeft from '@iconify-icons/fe/arrow-left';
import arrowRight from '@iconify-icons/fe/arrow-right';

import WizTopLeft from '@assets/svg/wiz_top_left.svg';
SwiperCore.use([Navigation]);

const StyledWrapper = styled.section`
  padding: 200px 0 0 0;
  overflow: hidden;
  @media (min-width: 1025px) {
    padding: 200px 0 100px 0;
  }

  .swiper-wrapper {
    display: inline-flex;
    align-items: center;
  }

  .swiper-container {
    .swiper-wrapper {
      .swiper-slide-duplicate {
        opacity: 0;
        visibility: hidden;
      }
      .swiper-slide:last-child {
        @media (min-width: 1025px) {
          margin-right: 500px !important;
        }
      }
    }
  }
`;

const GalleryWrapper = styled.div`
  max-width: 1250px;
  margin: -160px auto 0 auto;
  padding-left: 15px;

  @media (min-width: 768px) {
    margin: -118px auto 0 auto;
    padding-left: 15px;
  }
  @media (min-width: 1025px) {
    margin: -146px auto 0 auto;
    padding-left: 15px;
  }
`;

const StyledNavigation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  margin: 50px 15px 0 0;

  @media (min-width: 1025px) {
    order: -1;
    justify-content: flex-start;
    padding-left: 8%;
    margin-bottom: 100px;
  }

  @media (min-width: 1620px) {
    padding-left: 15%;
  }

  @media (min-width: 1820px) {
    padding-left: 20%;
  }
`;

const StyledNavButton = styled.button`
  background: ${({ theme }) => theme.primary};
  border: 0;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  color: ${({ theme }) => theme.background};
  font-size: 4.5rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 50px;
  }

  img {
    width: 100px;
  }
  svg {
    width: 20px;
    height: 20px;
  }
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  cursor: pointer;
  border-radius: 30px;
  width: 320px;
  overflow: hidden;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);

  @media (min-width: 768px) {
    width: 420px;
  }
  @media (min-width: 1025px) {
    width: 520px;
  }
`;

const GalleryText = styled.div`
  background-color: ${({ theme }) => theme.lightGrey};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 50px 0 200px 0;
  max-width: 1250px;
  margin: 0 auto;
  position: relative;

  @media (min-width: 1025px) {
    border-radius: 0 100px 100px 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50vw;
    height: 100%;
    transform: translateX(-100%);
    background-color: ${({ theme }) => theme.lightGrey};
  }

  .text_top_left {
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    transform: translate(10%, -70%);

    @media (min-width: 1025px) {
      width: 20%;
    }
  }
`;

const StyledParagraph = styled(Paragraph)`
  line-height: 1.8;
  max-width: 400px;
`;

const StyledHeadline = styled(Headline)`
  margin-bottom: 30px;
`;

const Gallery: FC<Props> = ({ images, fixedImages }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [swiperIndex, setSwiperIndex] = useState<number>(0);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [canLoop, setCanLoop] = useState<boolean>(true);

  const handleClick = () => setOpen(true);

  const handleNavRightClick = () => {
    const { activeIndex } = swiperInstance;

    const isActiveIndexEqualToLast = activeIndex === images.length - 1;

    if (isActiveIndexEqualToLast && canLoop) {
      swiperInstance.slideTo(0);
    } else if (isActiveIndexEqualToLast && !canLoop) {
      setCanLoop(true);
    } else if (!isActiveIndexEqualToLast && canLoop) {
      setCanLoop(false);
    }
  };

  const handleNavLeftClick = () => {
    const { activeIndex } = swiperInstance;

    const isActiveIndexEqualToFirst = activeIndex === 0;

    if (isActiveIndexEqualToFirst && canLoop) {
      swiperInstance.slideTo(images.length - 1);
    } else if (isActiveIndexEqualToFirst && !canLoop) {
      setCanLoop(true);
    } else if (!isActiveIndexEqualToFirst && canLoop) {
      setCanLoop(false);
    }
  };

  return (
    <StyledWrapper id="gallery">
      <GalleryText>
        <StyledHeadline as="h2" $isHeadline2 $isMainColor data-aos="fade-down">
          Wizualizacje
        </StyledHeadline>
        <StyledParagraph data-aos="fade-up" data-aos-delay="200">
          Zapraszamy do zapoznania się z wizualizacjami w naszej galerii.
          Sprawdź jak możesz mieszkać na osiedlu Eko Wkra.
        </StyledParagraph>
        <img src={WizTopLeft} alt="" className="text_top_left" />
      </GalleryText>
      <GalleryWrapper>
        <Swiper
          spaceBetween={30}
          slidesPerView="auto"
          centeredSlides={false}
          speed={500}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          grabCursor
          updateOnWindowResize
          breakpoints={{
            768: {
              slidesPerView: 'auto',
              spaceBetween: 50,
              centeredSlides: false,
            },
            1025: {
              slidesPerView: 'auto',
              spaceBetween: 50,
              centeredSlides: false,
            },
            1410: {
              centeredSlides: false,
              slidesPerView: 'auto',
              spaceBetween: 50,
            },
          }}
          onSwiper={setSwiperInstance}
        >
          {images.map((data, index) => (
            <SwiperSlide
              key={index}
              onClick={() => {
                setActiveIndex(index);
              }}
            >
              <StyledGatsbyImage
                alt="Galeria Eko Wkra"
                image={data.childImageSharp.gatsbyImageData}
                onClick={handleClick}
              />
            </SwiperSlide>
          ))}
          <StyledNavigation>
            <StyledNavButton
              aria-label="Poprzednie zdjęcie"
              aria-disabled={activeIndex === 0}
              className="swiper-button-prev"
              onClick={handleNavLeftClick}
            >
              <Icon icon={arrowLeft} />
            </StyledNavButton>
            <StyledNavButton
              aria-label="Następne zdjęcie"
              aria-disabled={activeIndex === images.length - 1}
              className="swiper-button-next"
              onClick={handleNavRightClick}
            >
              <Icon icon={arrowRight} />
            </StyledNavButton>
          </StyledNavigation>
        </Swiper>
      </GalleryWrapper>
      {isOpen && (
        <Lightbox
          mainSrc={fixedImages[activeIndex]}
          nextSrc={fixedImages[(activeIndex + 1) % fixedImages.length]}
          prevSrc={
            fixedImages[
              (activeIndex + fixedImages.length - 1) % fixedImages.length
            ]
          }
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setActiveIndex((activeIndex - 1) % fixedImages.length)
          }
          onMoveNextRequest={() =>
            setActiveIndex((activeIndex + 1) % fixedImages.length)
          }
        />
      )}
    </StyledWrapper>
  );
};

interface Props {
  images: any[];
  fixedImages: string[];
}

export default Gallery;

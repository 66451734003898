import React from 'react';
import styled from 'styled-components';
import Headline from '@components/atoms/Headline/Headline';
import Paragraph from '@components/atoms/Paragraph/Paragraph';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

const SectionWrapper = styled.section`
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
  @media (min-width: 1025px) {
    max-width: 1250px;
  }
`;
const HeadingWrapper = styled.div`
  padding: 50px 0;
  display: flex;
  justify-content: center;
  @media (min-width: 1025px) {
    padding: 50px 0 150px 0;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
  @media (min-width: 1025px) {
    flex-direction: row;
    padding-bottom: 200px;
  }
`;

const SectionContainerLeft = styled(SectionContainer)`
  @media (min-width: 1025px) {
    flex-direction: row-reverse;
  }
`;

const SectionImg = styled.div`
  text-align: center;
  width: 100%;
  @media (min-width: 1025px) {
    width: 55%;
  }
`;
const SectionText = styled.div`
  position: relative;
  text-align: center;
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 1025px) {
    align-items: flex-start;
    width: 45%;
    padding-left: 20%;
  }
`;

const SectionTextLeft = styled(SectionText)`
  @media (min-width: 1025px) {
    padding-left: 0;
    padding-right: 20%;
  }
`;

const StyledTitle = styled(Headline)`
  font-weight: ${({ theme }) => theme.regular};
  font-size: ${({ theme }) => theme.bodyL};
  max-width: 200px;
`;

const HorizontalLine = styled.span`
  display: none;
  @media (min-width: 1025px) {
    display: block;
    position: absolute;
    top: -80%;
    left: 0;
    width: 50vw;
    height: 1px;
    margin-left: 23%;
    background-color: ${({ theme }) => theme.grey};
    z-index: -1;
  }
`;

const HorizontalLineLeft = styled(HorizontalLine)`
  left: unset;
  right: 0;
  margin-left: 0;
  margin-right: 23%;
`;

const RealisationAuthor = styled.div`
  width: 220px;
  height: 50px;
  background-color: ${({ theme }) => theme.grey};
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RealisationNumber = styled.div`
  display: none;
  @media (min-width: 1025px) {
    position: absolute;
    top: -80%;
    left: 23%;
    width: 50px;
    height: 120px;
    background-color: ${({ theme }) => theme.grey};
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    transform: translateY(-100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      margin: 5px;
    }
  }
`;

const RealisationNumberLeft = styled(RealisationNumber)`
  left: unset;
  right: 23%;
`;

const NumberSpace = styled.span`
  display: block;
  width: 1px;
  height: 40px;
  background-color: ${({ theme }) => theme.darkBlue};
`;

const StyledBgLine = styled.span`
  position: absolute;
  top: 0;
  left: 50%;
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.grey};
  transform: translateX(-50%);
  z-index: -1;
`;

const StyledBgLineLeft = styled(StyledBgLine)`
  left: 20%;
  @media (min-width: 1025px) {
    left: 35%;
  }
`;
const StyledBgLineRight = styled(StyledBgLine)`
  left: 80%;

  @media (min-width: 1025px) {
    left: 65%;
  }
`;

const Realisations = () => {
  const data = useStaticQuery(query);

  return (
    <SectionWrapper id="our-realisations">
      <HeadingWrapper>
        <Headline as="h2" $isMainColor $isHeadline2 data-aos="fade-down">
          Nasze realizacje
        </Headline>
      </HeadingWrapper>
      <SectionContainer>
        <SectionImg data-aos="image-animation">
          <GatsbyImage
            image={data.twelveApartment.childImageSharp.gatsbyImageData}
            alt="12 mieszkaniowy blok w Katowicach przy ul. Sobocińskiego"
          />
        </SectionImg>
        <SectionText>
          <StyledTitle as="h3" data-aos="title-animation">
            <b>12 mieszkaniowy blok w Katowicach </b>przy ul. Sobocińskiego
          </StyledTitle>
          <RealisationAuthor>
            <Paragraph>
              Realizacja: <b>BBE Inwestycje</b>
            </Paragraph>
          </RealisationAuthor>
          <RealisationNumber>
            <Paragraph>
              <b>01</b>
            </Paragraph>
            <NumberSpace></NumberSpace>
            <Paragraph>
              <b>04</b>
            </Paragraph>
          </RealisationNumber>
          <HorizontalLine></HorizontalLine>
        </SectionText>
      </SectionContainer>

      <SectionContainerLeft>
        <SectionImg data-aos="image-animation">
          <GatsbyImage
            image={data.villaSunGarden.childImageSharp.gatsbyImageData}
            alt="Willa Sun Garden w Mławie przy ul. Rzęgnowskiej 13"
          />
        </SectionImg>
        <SectionTextLeft>
          <StyledTitle as="h3" data-aos="title-animation">
            <b>Willa Sun Garden w Mławie </b>przy ul. Rzęgnowskiej 13
          </StyledTitle>
          <RealisationAuthor>
            <Paragraph>
              Realizacja: <b>Bud-Pol</b>
            </Paragraph>
          </RealisationAuthor>
          <RealisationNumberLeft>
            <Paragraph>
              <b>02</b>
            </Paragraph>
            <NumberSpace></NumberSpace>
            <Paragraph>
              <b>04</b>
            </Paragraph>
          </RealisationNumberLeft>
          <HorizontalLineLeft></HorizontalLineLeft>
        </SectionTextLeft>
      </SectionContainerLeft>

      <SectionContainer>
        <SectionImg data-aos="image-animation">
          <GatsbyImage
            image={data.skpRs.childImageSharp.gatsbyImageData}
            alt="SKP RS auto w Katowicach przy ul. Ceglanej"
          />
        </SectionImg>
        <SectionText>
          <StyledTitle as="h3" data-aos="title-animation">
            <b>SKP RS auto w Katowicach </b>przy ul. Ceglanej
          </StyledTitle>
          <RealisationAuthor>
            <Paragraph>
              Realizacja: <b>BBE Inwestycje</b>
            </Paragraph>
          </RealisationAuthor>
          <RealisationNumber>
            <Paragraph>
              <b>03</b>
            </Paragraph>
            <NumberSpace></NumberSpace>
            <Paragraph>
              <b>04</b>
            </Paragraph>
          </RealisationNumber>
          <HorizontalLine></HorizontalLine>
        </SectionText>
      </SectionContainer>

      <SectionContainerLeft>
        <SectionImg data-aos="image-animation">
          <GatsbyImage
            image={data.renovationBuilding.childImageSharp.gatsbyImageData}
            alt="Renowacja Budynku Starostwa Powiatowego Mława"
          />
        </SectionImg>
        <SectionTextLeft>
          <StyledTitle as="h3" data-aos="title-animation">
            <b>Renowacja Budynku Starostwa Powiatowego Mława</b>
          </StyledTitle>
          <RealisationAuthor>
            <Paragraph>
              Realizacja: <b>Bud-Pol</b>
            </Paragraph>
          </RealisationAuthor>
          <RealisationNumberLeft>
            <Paragraph>
              <b>04</b>
            </Paragraph>
            <NumberSpace></NumberSpace>
            <Paragraph>
              <b>04</b>
            </Paragraph>
          </RealisationNumberLeft>
          <HorizontalLineLeft></HorizontalLineLeft>
        </SectionTextLeft>
      </SectionContainerLeft>

      <StyledBgLine></StyledBgLine>
      <StyledBgLineLeft></StyledBgLineLeft>
      <StyledBgLineRight></StyledBgLineRight>
    </SectionWrapper>
  );
};

export const query = graphql`
  {
    twelveApartment: file(name: { eq: "twelveApartment" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
        )
      }
    }
    villaSunGarden: file(name: { eq: "Villa-Sun-Garden" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
        )
      }
    }
    skpRs: file(name: { eq: "SKP-RS" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
        )
      }
    }
    renovationBuilding: file(name: { eq: "renovation-building" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
        )
      }
    }
  }
`;

export default Realisations;

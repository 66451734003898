import React from 'react';
import styled from 'styled-components';
import Headline from '@components/atoms/Headline/Headline';
import Paragraph from '@components/atoms/Paragraph/Paragraph';
import standardItems from '@config/standardItems';
import StandardTopLeftSvg from '@assets/svg/offer_circle.svg';
import StandardTopRightSvg from '@assets/svg/offer_top_left.svg';

const SectionWrapper = styled.section`
  position: relative;
  padding: 0 15px;
  margin: 0 auto;
  max-width: 1250px;
  text-align: center;

  @media (min-width: 1025px) {
    padding-top: 150px;
  }

  h2 {
    text-align: center;
  }
`;

const SectionContainer = styled.div`
  padding-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  @media (min-width: 576px) {
    grid-template-columns: repeat(2, 200px);
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 200px);
    padding-top: 100px;
  }
  @media (min-width: 1025px) {
    grid-template-columns: repeat(3, 250px);
    justify-content: space-between;
    padding: 100px 14% 0 14%;
  }
`;

const StandardBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 10px;
  background-color: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  border-radius: 40px 0 40px 0;
  text-align: center;
  height: 230px;
  margin-bottom: 50px;
  transition: transform 0.4s ease-out, background-color 0.4s ease-out !important;
  overflow: hidden;
  &:last-child {
    transform: translateX(50%);

    &:hover {
      transform: translate(50%, -15%);
    }
  }
  @media (max-width: 320px) {
    height: 230px;
  }
  @media (min-width: 576px) {
    height: 250px;
  }
  @media (min-width: 768px) {
    &:last-child {
      transform: translateX(0);
      &:hover {
        transform: translate(0, -15%);
      }
    }
  }
  @media (min-width: 1025px) {
    padding: 30px 10px;
    margin-bottom: 50px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.primary};
    transform: translateY(-15%);
    p,
    h3 {
      color: #fff;
      transition: color 0.4s ease-out;
    }

    img {
      filter: invert(1);
      transition: filter 0.4s ease-out;
    }
  }
`;

const StyledImg = styled.img`
  width: 50px;
  height: 50px;
  @media (min-width: 1025px) {
    width: 80px;
    height: 80px;
  }
`;

const StyledHeadline = styled(Headline)`
  font-size: ${({ theme }) => theme.bodyM};
  margin: 15px 0 5px 0;
  min-height: 30px;
  @media (max-width: 320px) {
    font-size: ${({ theme }) => theme.bodyXS};
  }
`;

const StyledParagraph = styled(Paragraph)`
  font-size: ${({ theme }) => theme.bodyXS};
  @media (min-width: 576px) {
    font-size: ${({ theme }) => theme.bodyXS};
  }
`;

const StyledBgLine = styled.span`
  position: absolute;
  top: 0;
  left: 50%;
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.grey};
  transform: translateX(-50%);
  z-index: -1;
`;

const StyledBgLineLeft = styled(StyledBgLine)`
  left: 20%;
  @media (min-width: 1025px) {
    left: 35%;
  }
`;
const StyledBgLineRight = styled(StyledBgLine)`
  left: 80%;

  @media (min-width: 1025px) {
    left: 65%;
  }
`;

const StyledTopLeftSVG = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  transform: translate(20%, -120%);
  @media (min-width: 1025px) {
    width: 200px;
    transform: translate(20%, 50%);
  }
`;

const StyledTopRightSVG = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 150px;
  transform: translate(-20%, -120%);
  @media (min-width: 1025px) {
    width: 150px;
    transform: translate(-60%, 50%);
  }
`;

const BoxOverlay = styled.div`
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.primary};
`;

const Standard = () => {
  return (
    <SectionWrapper id="standard">
      <Headline as="h2" $isHeadline2 $isMainColor data-aos="fade-down">
        Standard wykończenia
      </Headline>

      <SectionContainer className="standard_trigger">
        {standardItems.map((item, index) => (
          <StandardBox key={index}>
            <StyledImg
              src={require(`../../../assets/svg/${item.img}.svg`).default}
              alt=""
            />
            <StyledHeadline as="h3">{item.title}</StyledHeadline>
            <StyledParagraph className="item-desc">{item.desc}</StyledParagraph>
            <BoxOverlay
              data-aos="slide-down"
              data-aos-delay={`${index}00`}
              data-aos-anchor=".standard_trigger"
            ></BoxOverlay>
          </StandardBox>
        ))}
      </SectionContainer>
      <StyledTopRightSVG src={StandardTopRightSvg} alt="" />
      <StyledTopLeftSVG src={StandardTopLeftSvg} alt="" />
      <StyledBgLine></StyledBgLine>
      <StyledBgLineLeft></StyledBgLineLeft>
      <StyledBgLineRight></StyledBgLineRight>
    </SectionWrapper>
  );
};

export default Standard;

import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import BasicTemplate from '@templates/BasicTemplate';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import Headline from '@components/atoms/Headline/Headline';
import Button from '@components/atoms/Button/Button';
import Paragraph from '@components/atoms/Paragraph/Paragraph';
import Gallery from '@components/organisms/Gallery/Gallery';
import Visualisation from '@components/organisms/Visualisation/Visualisation';
import Standard from '@components/organisms/Standard/Standard';
import Contact from '@components/organisms/Contact/Contact';
import Realisations from '@components/organisms/Realisations/Realisations';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import scrollTo from '@utils/scrollTo';
import HeroTopRight from '@assets/svg/2.svg';
import HeroBottomLeft from '@assets/svg/1.svg';
import CircleSVG from '@assets/svg/circle.svg';
import TreeBg from '@assets/svg/bg_tree.svg';
import SecurityBg from '@assets/svg/security_bg.svg';
import LocationBg from '@assets/svg/location_bg.svg';
import Localisation1 from '@assets/svg/localisation1.svg';
import Localisation2 from '@assets/svg/localisation2.svg';
import Localisation3 from '@assets/svg/localisation3.svg';
import AboutUs from "@components/molecules/AboutUs/AboutUs";

SwiperCore.use([Autoplay, Pagination]);

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;

  margin: 0 auto;
  padding-top: 150px;
  @media (min-width: 576px) {
    max-width: 576px;
  }
  @media (min-width: 1025px) {
    flex-direction: row;
    align-items: center;
    padding-top: 150px;
    max-width: 1250px;
  }
`;

const HeroText = styled.div`
  padding: 0 20px;
  @media (min-width: 1025px) {
    width: 40%;
  }
`;

const Underscore = styled.span`
  position: relative;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 50%;
    top: 50%;
    left: 0;
    background-color: ${({ theme }) => theme.primary};
    z-index: -1;
  }
`;

const HeroImg = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 50px 0 30px 0;
  padding: 0 15px;

  @media (min-width: 1025px) {
    width: 60%;
    margin: 0;
    padding: 0;
  }
  .swiper-container {
    width: 100%;

    .swiper-wrapper {
      display: inline-flex;
      align-items: center;
    }
  }

  .hero_top_right {
    position: absolute;
    top: 0;
    right: 0;
    width: 20%;
    height: auto;
    transform: translate(-10%, -20%);
  }
  .hero_bottom_left {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 25%;
    height: auto;
    transform: translate(5%, 40%);
  }
`;

const HeroSlider = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .swiper-container {
    width: 100%;

    .swiper-wrapper {
      display: inline-flex;
      align-items: center;
    }
  }
`;

const HeroSliderPagination = styled.div`
  position: absolute;
  bottom: -30px;
  right: 10px;
  display: flex;

  .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.grey};
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }
  .swiper-pagination-bullet-active {
    background-color: ${({ theme }) => theme.primary};
    cursor: pointer;
  }
`;

const HeroButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
  @media (min-width: 1025px) {
    justify-content: flex-start;
  }
`;

const AboutSection = styled.section`
  background-color: ${({ theme }) => theme.lightGrey};
  padding: 30px 20px 150px 20px;
  position: relative;
  @media (min-width: 576px) {
    max-width: 576px;
    margin: 0 auto;
    border-radius: 0 100px 100px 0;
    padding: 50px 100px 200px 100px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 50vw;
      height: 100%;
      transform: translateX(-100%);
      background-color: ${({ theme }) => theme.lightGrey};
    }
  }
  @media (min-width: 1025px) {
    max-width: 1250px;
    display: flex;
    flex-direction: row-reverse;
    margin: 100px auto 0 auto;
    border-radius: 0 100px 100px 0;
    padding: 100px 100px 200px 0;
  }

  .paragraph_special_margin {
    margin: 20px 0;
  }
`;

const AboutSectionText = styled.div`
  @media (min-width: 1025px) {
    width: 50%;
    padding-left: 10%;
  }

  p:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const StyledHeadline = styled(Headline)`
  font-size: 20px;
  margin-bottom: 30px;
`;

const AboutSectionImg = styled.div`
  position: relative;
  margin: 50px 0;

  @media (min-width: 1025px) {
    width: 50%;
    margin: 0;
  }

  .about_bottom_left {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 20%;
    height: auto;
    transition: transform 1s ease-out;
    @media (min-width: 1025px) {
      transform: translate(-25%, -25%);
    }
  }
  .about_top_right {
    position: absolute;
    top: 0;
    right: 0;
    width: 20%;
    height: auto;
    transform: translate(-15%, -50%);
    transition: transform 1s ease-out;
    @media (min-width: 1025px) {
      transform: translate(18%, -40%);
    }
  }
  .about_top_left {
    position: absolute;
    top: 0;
    left: 0;
    width: 20%;
    height: auto;
    transform: translate(-10%, -20%);
  }
`;

const FeaturesSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10;
  max-width: 1250px;
  margin: 0 auto;
  margin-top: -125px;
  margin-bottom: 50px;
  @media (min-width: 1025px) {
    flex-direction: row;
    margin-bottom: 100px;
  }
`;
const FeatureBox = styled.div`
  width: 220px;
  height: 250px;
  box-shadow: 0 30px 30px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 30px 100px 30px 30px;
  padding: 30px 20px;
  margin-bottom: 50px;
  @media (min-width: 1025px) {
    margin-bottom: 0;

    &:nth-child(2) {
      margin: 0 20%;
    }
  }

  p {
    font-weight: ${({ theme }) => theme.bold};
  }

  .feature_box_img {
    width: 40%;
    margin-bottom: 30px;
  }
`;

const LocalizationSection = styled.section`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 576px) {
    max-width: 576px;
  }
  @media (min-width: 1025px) {
    max-width: 1250px;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 1025px) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

const MapWrapper = styled.div`
  width: 100%;
  padding: 0 15px;
  @media (min-width: 1025px) {
    width: 70%;
    padding: 0;
  }
`;

const InfographicWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 30px;
  @media (min-width: 1025px) {
    padding: 0;
    width: 20%;
  }
`;
const InfographicBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 300px;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  @media (min-width: 1025px) {
    max-width: unset;
  }
`;

const StyledInfoImg = styled.img`
  width: 100%;
  margin-bottom: 20px;
`;

const IndexPage: FC<Props> = ({
  data: {
    aboutEstate,
    gallery,
    galleryFixed,
    hero1,
    hero2,
    hero3,
    infographic,
  },
}) => {
  const [activeSlide] = useState(1);
  const galleryImages = gallery.nodes;

  let galleryFixedImages = galleryFixed.nodes.map(
    ({
      childImageSharp: {
        fixed: { src },
      },
    }) => src
  );

  let defaultWidth;
  if (typeof window !== `undefined`) {
    defaultWidth = window.innerWidth;
  }
  const useWindowSize = () => {
    const [width, setWidth] = useState({
      width: defaultWidth,
    });
    useEffect(() => {
      const handleResize = () =>
        setWidth({
          width: window.innerWidth,
        });
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    return width;
  };
  const { width } = useWindowSize();

  const handleScrollableLink = (e: any, target: string) => {
    e.preventDefault();
    scrollTo(target);
  };

  return (
    <BasicTemplate title={undefined}>
      <HeroSection id="hero">
        <HeroText>
          <Headline data-aos="fade-up" data-aos-delay="100">
            Pierwsze tak unikatowe osiedle w <Underscore>Strzegowie</Underscore>
          </Headline>
          {width >= 1025 && (
            <HeroButtons>
              <Button>
                <a
                  href="/#houses"
                  onClick={(e) => {
                    handleScrollableLink(e, 'houses');
                  }}
                >
                  Sprawdź ofertę
                </a>
              </Button>
              <Button isRight>
                <a
                  href="/#contact"
                  onClick={(e) => {
                    handleScrollableLink(e, 'contact');
                  }}
                >
                  Skontaktuj się
                </a>
              </Button>
            </HeroButtons>
          )}
        </HeroText>
        <HeroImg>
          <HeroSlider>
            <Swiper
              spaceBetween={30}
              slidesPerView={1}
              speed={500}
              autoplay={true}
              pagination={{
                el: '.swiper-pagination',
                clickable: true,
              }}
              grabCursor
              updateOnWindowResize
            >
              <SwiperSlide>
                <GatsbyImage
                  alt="Pierwsze tak unikatowe osiedle w Strzegowie"
                  className={
                    activeSlide === 1
                      ? 'hero_slider hero_slider--active'
                      : 'hero_slider'
                  }
                  image={hero1.childImageSharp.gatsbyImageData}
                />
              </SwiperSlide>
              <SwiperSlide>
                <GatsbyImage
                  alt="Pierwsze tak unikatowe osiedle w Strzegowie"
                  className={
                    activeSlide === 2
                      ? 'hero_slider hero_slider_second hero_slider--active'
                      : 'hero_slider hero_slider_second'
                  }
                  image={hero2.childImageSharp.gatsbyImageData}
                />
              </SwiperSlide>
              <SwiperSlide>
                <GatsbyImage
                  alt="Pierwsze tak unikatowe osiedle w Strzegowie"
                  className={
                    activeSlide === 3
                      ? 'hero_slider hero_slider_third hero_slider--active'
                      : 'hero_slider hero_slider_third'
                  }
                  image={hero3.childImageSharp.gatsbyImageData}
                />
              </SwiperSlide>
            </Swiper>
            <HeroSliderPagination className="swiper-pagination" />
          </HeroSlider>
          <img src={HeroTopRight} alt="" className="hero_top_right" />
          <img src={HeroBottomLeft} alt="" className="hero_bottom_left" />
        </HeroImg>
        {width <= 1025 && (
          <HeroButtons>
            <Button>
              <a
                href="/#houses"
                onClick={(e) => {
                  handleScrollableLink(e, 'houses');
                }}
              >
                Sprawdź ofertę
              </a>
            </Button>
            <Button isRight>
              <a
                href="/#contact"
                onClick={(e) => {
                  handleScrollableLink(e, 'contact');
                }}
              >
                Skontaktuj się
              </a>
            </Button>
          </HeroButtons>
        )}
      </HeroSection>
      <AboutSection id="about">
        <AboutSectionText>
          <StyledHeadline
            as="h2"
            $isHeadline2
            $isMainColor
            data-aos="fade-down"
          >
            O osiedlu
          </StyledHeadline>
          {width >= 1025 && (
            <Headline
              as="h3"
              $isHeadline3
              data-aos="fade-left"
              data-aos-delay="300"
            >
              Osiedle z dostępem do spływów kajakowych!
            </Headline>
          )}
          <Paragraph>
            Eko Wkra to unikatowe, <b>nowe</b> 8 segmentowe osiedle znajdujące
            się <u>na wzgórzu</u> Wkry w Strzegowie. Okolica jest bardzo
            spokojna i bezpieczna. Osiedle z dostępem do drogi asfaltowej i
            trasy rowerowej.
          </Paragraph>
          <Paragraph>
            Dużym atutem okolicy jest <b>nowo powstająca droga </b>
            ekspresowa S7, która znacznie przyspieszy podróż do Warszawy lub
            Gdańska. Osiedle ma <b>bezpośredni dostęp do rzeki</b> Wkra, a co za
            tym idzie pojawia się możliwość{' '}
            <u>spływów kajakowych z własnej posesji.</u>
            Psst. planowany jest <b>zalew</b> tuż za rzeką!
          </Paragraph>

          {width >= 1025 && (
            <>
              <Paragraph>
                Wokół osiedla nie brakuje również rozległych terenów zielonych,
                które pozwalają na spędzenie czasu z rodziną oraz przyjaciółmi w{' '}
                <u>naturalnym, spokojnym środowisku.</u>
              </Paragraph>
              <Paragraph>
                Liczne szlaki spacerowe i ścieżki rowerowe pozwalają
                <b> aktywnie</b> spędzać czas w weekendy, a miłośnikom zwierząt
                dają możliwość na <b>długie</b> spacery ze swoimi pupilami.
              </Paragraph>
              <Button>
                <a
                  href="/#contact"
                  onClick={(e) => {
                    handleScrollableLink(e, 'contact');
                  }}
                >
                  Skontaktuj się
                </a>
              </Button>
            </>
          )}
        </AboutSectionText>
        <AboutSectionImg className="about_anim_trigger">
          <GatsbyImage
            alt="O osiedlu"
            image={aboutEstate.childImageSharp.gatsbyImageData}
          />

          <img
            src={HeroTopRight}
            alt=""
            className="about_bottom_left"
            id="animation_about_bottom_left"
          />
          <img src={HeroTopRight} alt="" className="about_top_right" />
          <img src={CircleSVG} alt="" className="about_top_left" />
        </AboutSectionImg>
        {width <= 1025 && (
          <>
            <Paragraph>
              Wokół osiedla nie brakuje również rozległych terenów zielonych,
              które pozwalają na spędzenie czasu z rodziną oraz przyjaciółmi w{' '}
              <u>naturalnym, spokojnym środowisku.</u>
            </Paragraph>
            <Paragraph className="paragraph_special_margin">
              Liczne szlaki spacerowe i ścieżki rowerowe pozwalają
              <b> aktywnie</b> spędzać czas w weekendy, a miłośnikom zwierząt
              dają możliwość na <b>długie</b> spacery ze swoimi pupilami.
            </Paragraph>
          </>
        )}
      </AboutSection>
      <FeaturesSection>
        <FeatureBox data-aos="fade-up">
          <img src={TreeBg} alt="" className="feature_box_img" />
          <Paragraph>Dostęp do terenów zielonych i natury</Paragraph>
        </FeatureBox>
        <FeatureBox data-aos="fade-up" data-aos-delay="300">
          <img src={SecurityBg} alt="" className="feature_box_img" />
          <Paragraph>Zapewnienie wysokiej jakości wykonawstwa</Paragraph>
        </FeatureBox>
        <FeatureBox data-aos="fade-up" data-aos-delay="600">
          <img src={LocationBg} alt="" className="feature_box_img" />
          <Paragraph>Szybki dojazd do Gdańska i Warszawy</Paragraph>
        </FeatureBox>
      </FeaturesSection>
      <LocalizationSection id="localization">
        <Headline as="h2" $isHeadline2 $isMainColor data-aos="fade-down">
          Lokalizacja
        </Headline>

        <SectionWrapper>
          <MapWrapper>
            <GatsbyImage
              alt="Mapa"
              image={infographic.childImageSharp.gatsbyImageData}
            />
          </MapWrapper>

          <InfographicWrapper className="infographic-wrapper">
            <InfographicBox
              data-aos="fade-right"
              data-aos-anchor=".infographic-wrapper"
            >
              <StyledInfoImg src={Localisation1} alt="" />
              <Paragraph>
                Osiedle z bliskim dostępem do terenów zielonych i rekreacyjnych
              </Paragraph>
            </InfographicBox>
            <InfographicBox
              data-aos="fade-right"
              data-aos-delay="300"
              data-aos-anchor=".infographic-wrapper"
            >
              <StyledInfoImg src={Localisation2} alt="" />
              <Paragraph>
                Osiedle zlokalizowane na wzgórzu Wkry z bezpośrednim zejściem do
                rzeki
              </Paragraph>
            </InfographicBox>
            <InfographicBox
              data-aos="fade-right"
              data-aos-delay="600"
              data-aos-anchor=".infographic-wrapper"
            >
              <StyledInfoImg src={Localisation3} alt="" />
              <Paragraph>
                Możliwość podziwiania malowniczej Okolicy podczas rodzinnej
                wycieczki rowerowej
              </Paragraph>
            </InfographicBox>
          </InfographicWrapper>
        </SectionWrapper>
      </LocalizationSection>

      <Gallery images={galleryImages} fixedImages={galleryFixedImages} />

      <Visualisation />

      <Standard />

      <AboutUs />

      <Realisations />

      <Contact />
    </BasicTemplate>
  );
};

interface GatsbyImageData {
  childImageSharp: {
    gatsbyImageData: any;
  };
}

interface Props {
  data: {
    gallery: {
      nodes: GatsbyImageData[];
    };
    galleryFixed: {
      nodes: { childImageSharp: { fixed: { src: string } } }[];
    };
    hero1: GatsbyImageData;
    hero2: GatsbyImageData;
    hero3: GatsbyImageData;
    aboutEstate: GatsbyImageData;
    infographic: GatsbyImageData;
    budPolImg: GatsbyImageData;
    bbeInvestmentsImg: GatsbyImageData;
  };
}

export const query = graphql`
  {
    gallery: allFile(
      filter: { name: { regex: "/1_4K|2_4K|3_4K|4_4K|5_4K|6_4K|7_4K/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 80
            placeholder: BLURRED
            formats: [WEBP, JPG]
            layout: FULL_WIDTH
          )
        }
      }
    }
    galleryFixed: allFile(
      filter: { name: { regex: "/1_4K|2_4K|3_4K|4_4K|5_4K|6_4K|7_4K/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          fixed(quality: 80, width: 3840) {
            src
          }
        }
      }
    }
    hero1: file(name: { eq: "hero1" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
        )
      }
    }
    hero2: file(name: { eq: "hero2" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
        )
      }
    }
    hero3: file(name: { eq: "hero3" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
        )
      }
    }
    aboutEstate: file(name: { eq: "about-estate" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
        )
      }
    }
    infographic: file(name: { eq: "infographic" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          placeholder: BLURRED
          formats: [WEBP, JPG]
        )
      }
    }
    budPolImg: file(name: { eq: "bud-pol" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
        )
      }
    }
    bbeInvestmentsImg: file(name: { eq: "bbe-investments" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
        )
      }
    }
  }
`;

export default IndexPage;
